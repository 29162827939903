
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      required: false,
      type: String,
      default: "Loading"
    }
  }
});
