
import { defineComponent, reactive, ref, toRefs } from "vue";
import base from "@/api/base.js";
import SignaturePad from "vue3-signature-pad";
import Spinner from "@/components/Spinner.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { isProxy, toRaw } from "vue";

export default defineComponent({
  name: "qsc-check",
  components: { SignaturePad, Spinner },
  data() {
    return {
      qscCheckList: {
        store: {},
        check_ver: {
          brand: { cmp: {} },
        },
        check_item: [
          {
            middle_ctgry: {},
            item_detail: [],
            result_detail: "",
            result_value: "",
          },
        ],
        isLoading: false,
        hasError: false,
      },
      // qscCheck: { result_value: "472" },
      fileList: [],
      newImage: [],
      scoreId: "",
      result: [],
      fileroute: "",
      largectgry: [],
      sigfile: "",
      rate: "",
      large_ctgry: "",
      LoadingStatus: true,
      sessionId: "",
      userType: "",
    };
  },

  props: { id: String },

  mounted() {
    this.sessionId = sessionStorage.getItem("id");
    this.userType = localStorage.getItem("userTy");
    this.getData();

    console.log("유저타입", this.userType);
  },
  methods: {
    getData() {
      const param = { large_ctgry_pk: this.large_ctgry };
      base
        .getQscCheckList(this.sessionId, param)
        .then((res) => {
          console.log(res);
          this.qscCheckList = res.data;
          const calrate =
            (this.qscCheckList.check_rslt_score_sum /
              this.qscCheckList.max_score_sum) *
            100;
          this.rate = calrate.toFixed(1);
          if (this.qscCheckList.max_score_sum == 0) {
            this.rate = 100;
          }
          this.LoadingStatus = false;
          this.getLargeCategory();
          this.getSelectedId(this.qscCheckList.check_item);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLargeCategory() {
      const data = {
        ctgry_ty: "CT100100",
        brand_id: this.qscCheckList.check_ver.brand.id,
      };

      base
        .getCategoryList(data)
        .then((res) => {
          console.log(res);
          this.largectgry = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getFilterData() {
      this.getData();
    },

    getSelectedId(check_item) {
      console.log("체크아이템", check_item);
      for (let i = 0; i < check_item.length; i++) {
        const item_detail = check_item[i].item_detail;
        const id = item_detail
          .filter((detail) => detail.result == true)
          .map((detail) => detail.id);
        const id2 = parseInt(id.toString());
        this.qscCheckList.check_item[i].result_value = id2;
        if (this.qscCheckList.check_item[i].check_item_ty === "QSCIT10050") {
          this.qscCheckList.check_item[i].result_value = id;
        }
        console.log("결과반영", this.qscCheckList.check_item[i].result_value);
      }
    },
    updateItemScore(questionIndex) {
      console.log("called updateItemScore");
      const question = this.qscCheckList.check_item[questionIndex];
      const item = this.qscCheckList.check_item[questionIndex].item_detail[0];
      if (
        question.check_rslt_score &&
        item.text_rslt.length >= item.text_min_length
      ) {
        question.check_rslt_score = question.item_max_score;
      } else {
        question.check_rslt_score = null;
      }
    },
    openPostcode(questionIndex) {
      console.log("called openPostCode");
      const updateItemScoreFunction = this.updateItemScore;
      const daum = (window as any).daum;
      const item = this.qscCheckList.check_item[questionIndex].item_detail[0];
      new daum.Postcode({
        oncomplete: (data) => {
          item.text_rslt = data.roadAddress + " ";
          updateItemScoreFunction(questionIndex);
          window.close();
        },
      }).open();
    },
    async submitForm(qscCheck) {
      qscCheck.isLoading = true;
      qscCheck.hasError = false;
      if (qscCheck.check_item_ty === "QSCIT10050") {
        qscCheck.isLoading = false;
        return;
      }
      console.log("수정데이터!!!", qscCheck);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      if (qscCheck.check_item_ty === "QSCIT10030") {
        if (qscCheck.item_max_score === 0) {
          qscCheck.check_rslt_score = 0;
        }
        this.submitFile(qscCheck);
      } else {
        if (qscCheck.check_item_ty === "QSCIT10010") {
          if (qscCheck.item_max_score === 0) {
            qscCheck.check_rslt_score = 0;
          }
          this.result = {
            check_result: this.qscCheckList.id,
            check_item: qscCheck.id,
            text_rslt: qscCheck.item_detail[0].text_rslt,
            rslt_rmks: qscCheck.rslt_rmks,
            check_rslt_score: qscCheck.check_rslt_score,
          };
        } else if (qscCheck.check_item_ty === "QSCIT10020") {
          this.result = {
            check_result: this.qscCheckList.id,
            check_item: qscCheck.id,
            exmp_rslt: qscCheck.result_value,
            rslt_rmks: qscCheck.rslt_rmks,
          };
        } else if (qscCheck.check_item_ty === "QSCIT10050") {
          this.result = {
            check_result: this.qscCheckList.id,
            check_item: qscCheck.id,
            exmp_rslt: qscCheck.result_value,
            rslt_rmks: qscCheck.rslt_rmks,
            is_checked: qscCheck.is_checked,
          };
        } else if (qscCheck.check_item_ty === "QSCIT10060") {
          if (qscCheck.item_max_score === 0) {
            qscCheck.check_rslt_score = 0;
          }
          this.result = {
            check_result: this.qscCheckList.id,
            check_item: qscCheck.id,
            text_rslt: qscCheck.item_detail[0].text_rslt,
            rslt_rmks: qscCheck.rslt_rmks,
            check_rslt_score: qscCheck.check_rslt_score,
          };
        } else {
          qscCheck.isLoading = false;
          qscCheck.hasError = true;
          alert("점검 항목의 유형이 올바르지 않습니다.");
          return;
        }
        console.log(this.result);

        const text = this.result.text_rslt;
        if (
          qscCheck.check_item_ty === "QSCIT10010" &&
          (typeof text === "undefined" ||
            text === null ||
            text.length < qscCheck.item_detail[0].text_min_length)
        ) {
          qscCheck.isLoading = false;
          qscCheck.hasError = true;
          alert("최소 입력 글자 수 이상으로 입력하세요.");
        } else if (
          qscCheck.item_max_score > 0 &&
          ((qscCheck.check_item_ty === "QSCIT10010" &&
            qscCheck.check_rslt_score === undefined) ||
            qscCheck.check_rslt_score === "" ||
            qscCheck.check_rslt_score === null)
        ) {
          qscCheck.isLoading = false;
          qscCheck.hasError = true;
          alert("항목 답변의 점수를 입력해주세요.");
        } else if (
          (qscCheck.check_item_ty === "QSCIT10020" &&
            this.result.exmp_rslt === undefined) ||
          this.result.exmp_rslt === ""
        ) {
          qscCheck.isLoading = false;
          qscCheck.hasError = true;
          alert("항목 답변을 선택해주세요.");
        } else {
          if (qscCheck.result_detail) {
            console.log(this.result);
            await base
              .updateQscCheck(this.result)
              .then((res) => {
                qscCheck.isLoading = false;
                console.log(res);
                if (res.status == 200) {
                  Toast.fire({
                    icon: "success",
                    title: "점검 결과를 수정했습니다.",
                  });
                  // alert("점검 결과를 수정했습니다.");
                  this.getCheckItem(qscCheck.id);
                } else {
                  qscCheck.hasError = true;
                  alert(res.status);
                }
              })
              .catch((err) => {
                qscCheck.isLoading = false;
                qscCheck.hasError = true;
                console.log(err);
                console.log(err.response);
                const value = Object.values(err.response.data);
                alert(value);
              });
          } else {
            await base
              .newQscCheck(this.result)
              .then((res) => {
                qscCheck.isLoading = false;
                console.log(res);
                if (res.status == 201) {
                  Toast.fire({
                    icon: "success",
                    title: "점검 결과를 등록했습니다.",
                  });
                  // alert("점검 결과를 등록했습니다.");
                  this.getCheckItem(qscCheck.id);
                } else {
                  qscCheck.hasError = true;
                  alert(res.status);
                }
              })
              .catch((err) => {
                qscCheck.isLoading = false;
                qscCheck.hasError = true;
                console.log(err);
                console.log(err.response);
                const value = Object.values(err.response.data);
                alert(value);
              });
          }
        }
      }

      if (qscCheck.isLoading) {
        qscCheck.isLoading = false;
      }
    },

    async submitFile(qscCheck) {
      qscCheck.isLoading = true;
      qscCheck.hasError = false;

      const formData = new FormData();
      formData.append("check_result", this.qscCheckList.id);
      formData.append("check_item", qscCheck.id);
      if (qscCheck.rslt_rmks) {
        formData.append("rslt_rmks", qscCheck.rslt_rmks);
      }
      formData.append("check_rslt_score", qscCheck.check_rslt_score);

      const file = this.fileList;

      file.forEach(function (item, idx) {
        for (const key in item) {
          formData.append(key + "[" + idx + "]", item[key]);
        }
      });

      const pairs = Array.from(formData.entries());
      for (const pair of pairs) {
        console.log(pair);
      }

      if (qscCheck.result_detail) {
        const id = this.qscCheckList.id;
        await base
          .updateQscCheckFile(formData, id)
          .then((res) => {
            qscCheck.isLoading = false;
            console.log(res);
            if (res.status == 200) {
              alert("점검 결과를 수정했습니다.");
              this.fileList.splice(0);
              this.getCheckItem(qscCheck.id);
            } else {
              qscCheck.hasError = true;
              alert(res.status);
            }
          })
          .catch((err) => {
            qscCheck.isLoading = false;
            qscCheck.hasError = true;
            console.log(err);
            console.log(err.response);
            const value = Object.values(err.response.data);
            alert(value);
          });
      } else {
        await base
          .newQscCheckFile(formData)
          .then((res) => {
            qscCheck.isLoading = false;
            console.log(res);
            if (res.status == 200) {
              alert("점검 결과를 등록했습니다.");
              this.fileList.splice(0);
              this.getCheckItem(qscCheck.id);
            } else {
              qscCheck.hasError = true;
              alert(res.status);
            }
          })
          .catch((err) => {
            qscCheck.isLoading = false;
            qscCheck.hasError = true;
            console.log(err);
            console.log(err.response);
            const value = Object.values(err.response.data);
            alert(value);
          });
      }

      if (qscCheck.isLoading) {
        qscCheck.isLoading = false;
      }
    },

    async getCheckItem(itemid) {
      const index = this.qscCheckList.check_item.findIndex(
        (q) => q.id == itemid
      );
      console.log(index);
      await base
        .getQscItem(this.qscCheckList.id, itemid)
        .then((res) => {
          console.log(res);
          const qscCheck = res.data;
          const item_detail = qscCheck.item_detail;
          const id = item_detail
            .filter((detail) => detail.result == true)
            .map((detail) => detail.id);
          qscCheck.result_value = parseInt(id.toString());
          if (qscCheck.check_item_ty === "QSCIT10050") {
            qscCheck.result_value = id;
          }
          this.qscCheckList.check_item[index] = qscCheck;
          console.log(this.qscCheckList.check_item[index]);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async finalSubmit() {
      if (this.qscCheckList.check_ver.check_type === "SELF") {
        const id = this.qscCheckList.id;
        const inspector = this.$refs.signaturePad_inspector.saveSignature();
        inspector.file = new File([inspector.file], "sign_inspector.png", {
          type: "image/png",
        });

        const formData = new FormData();
        formData.append("inspector_sgntr", inspector.file);
        formData.append("check_rmks", this.qscCheckList.check_rmks);

        const pairs = Array.from(formData.entries());
        for (const pair of pairs) {
          console.log(pair);
        }
        if (inspector.file.size <= 4) {
          alert("점검자 & 점검 대상자의 서명을 완료해주세요.");
        } else {
          await base
            .finalResult(formData, id)
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                alert("최종 점검 결과를 등록했습니다.");
                if (this.userType === "OMSUSR0150") {
                  this.$router.push("/menu/qsc/qsc-store");
                } else {
                  this.$router.push("/menu/qsc/qsc-sv");
                }
              } else {
                alert(res.status);
              }
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);
              const value = Object.values(err.response.data);
              alert(value);
            });
        }
      } else {
        const id = this.qscCheckList.id;
        const inspector = this.$refs.signaturePad_inspector.saveSignature();
        inspector.file = new File([inspector.file], "sign_inspector.png", {
          type: "image/png",
        });

        const inspected = this.$refs.signaturePad_inspected.saveSignature();
        inspected.file = new File([inspected.file], "sign_inspected.png", {});

        const formData = new FormData();
        formData.append("inspector_sgntr", inspector.file);
        formData.append("inspected_sgntr", inspected.file);
        formData.append("check_rmks", this.qscCheckList.check_rmks);

        const pairs = Array.from(formData.entries());
        for (const pair of pairs) {
          console.log(pair);
        }
        if (inspector.file.size <= 4 || inspected.file.size <= 4) {
          alert("점검자 & 점검 대상자의 서명을 완료해주세요.");
        } else {
          await base
            .finalResult(formData, id)
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                alert("최종 점검 결과를 등록했습니다.");
                this.$router.push("/menu/qsc/qsc-sv");
              } else {
                alert(res.status);
              }
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);
              const value = Object.values(err.response.data);
              alert(value);
            });
        }
      }
    },
    async checkItem(value, questionIndex, itemIndex) {
      console.log("called checkItem");
      console.log(value);
      console.log(this.qscCheckList);
      const question = this.qscCheckList.check_item[questionIndex];
      const item = question.item_detail[itemIndex];
      question.isLoading = true;
      item.is_checked = value;
      const remarks = "";
      let score = parseInt(question.check_rslt_score) || 0;
      if (value === true) {
        item.exmp_rslt_id = item.id;
        score += 1;
      } else {
        item.exmp_rslt_id = null;
        score -= 1;
      }

      if (score > question.item_max_score) {
        score = question.item_max_score;
      } else if (score <= 0) {
        score = 0;
      }
      question.check_rslt_score = score;

      console.log(question);
      console.log(item);
      const data = {
        inspectionId: this.qscCheckList.id,
        questionId: question.id,
        questionItemId: item.id,
        questionIndex: questionIndex,
        result: value,
        score: score,
        remarks: remarks,
      };
      console.log(data);
      await this.saveAnswerItem(data);

      question.result_detail = "true";
      if (question.isLoading) {
        question.isLoading = false;
      }
    },
    async saveAnswerItem(data, questionIndex) {
      console.log("called saveAnswerItem");

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      const inspectionId = data["inspectionId"];
      const questionId = data["questionId"];
      const questionItemId = data["questionItemId"];
      const question = this.qscCheckList.check_item[data["questionIndex"]];
      console.log(question);
      question.isLoading = true;
      question.hasError = false;

      const payload = data;
      delete payload["inspectionId"];
      delete payload["questionId"];
      delete payload["questionItemId"];
      delete payload["questionIndex"];

      await base
        .saveQscAnswerItem(inspectionId, questionId, questionItemId, payload)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            question.isLoading = false;
            Toast.fire({
              icon: "success",
              title: "점검 결과를 저장했습니다.",
            });
          } else {
            question.isLoading = false;
            question.hasError = true;
            alert(res.status);
          }
        })
        .catch((err) => {
          question.isLoading = false;
          question.hasError = true;
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    getSelected(e, detail, obj) {
      obj.result_value = e.target.value;
      console.log("called getSelected");
      console.log(obj);
      const score = detail
        .filter((list) => list.id == obj.result_value)
        .map((list) => list.item_score);
      console.log(score);
      obj.check_rslt_score = score;
      console.log(obj.check_rslt_score);
      // if (obj.check_item_ty == 'QSCIT10050') {
      //   e.target.checked=
      // }
      this.submitForm(obj);
    },

    addFile(fileTy) {
      console.log(fileTy);
      document.getElementById(fileTy).click();
    },

    uploadImage(e, id, obj, questionIndex, itemIndex) {
      console.log(id);
      console.log(e.target.files[0]);

      const itemId = this.fileList.some((f) => f.exmp_rslt == id);
      console.log(itemId);
      if (itemId) {
        alert("사진 파일이 이미 등록되어 있습니다. 삭제 후 업로드 하세요");
      } else {
        this.fileroute = (
          document.getElementById("upFile-" + obj.id) as HTMLInputElement
        ).value;

        obj.filename = this.fileroute;

        this.newImage = e.target.files[0];

        const question = this.qscCheckList.check_item[questionIndex];
        const item = question.item_detail[itemIndex];
        item.filename = this.newImage.name;
        this.fileList.push({
          exmp_rslt: id,
          image_file: this.newImage,
        });
        console.log(this.fileList);
      }
    },

    removeImage(id, questionIndex, itemIndex) {
      console.log(id);

      const index = this.fileList.findIndex((f) => f.exmp_rslt == id);
      console.log(index);

      const question = this.qscCheckList.check_item[questionIndex];
      const item = question.item_detail[itemIndex];
      item.filename = "";
      this.fileList.splice(index, 1);
      // (document.getElementById("fileName-" + id) as HTMLInputElement).value =
      //   "";
    },
  },
  setup() {
    const state = reactive({
      customStyle: {
        border: "gray 1px double",
        width: "100%",
      },
      saveType: "image/png",
      saveOutput: "file",
    });
    const signatureDataURL = ref(null);
    const signatureFile1 = ref(null);
    const signatureFile2 = ref(null);
    const signaturePad_inspected = ref(null);
    const signaturePad_inspector = ref(null);

    // const getSignaturePad = () => {
    //   if (signaturePad_inspected.value) {
    //     return signaturePad_inspected.value;
    //   } else if (signaturePad_inspector.value) {
    //     return signaturePad_inspector.value;
    //   }
    // };
    const getSignaturePad1 = () => {
      return signaturePad_inspector.value;
    };
    const getSignaturePad2 = () => {
      return signaturePad_inspected.value;
    };

    const clearSignature1 = () => {
      getSignaturePad1().clearSignature();
    };
    const clearSignature2 = () => {
      getSignaturePad2().clearSignature();
    };

    const saveSignature = () => {
      const signature = getSignaturePad1().saveSignature();
      console.log(signature);
      return signature;
    };

    const onInput1 = (value) => {
      console.log("calling on input1", value);

      if (!value) {
        signatureDataURL.value = null;
        signatureFile1.value = null;
      } else if (value instanceof File) {
        signatureDataURL.value = null;
        signatureFile1.value = value;
      } else {
        signatureDataURL.value = value;
        signatureFile1.value = null;
      }

      console.log(signatureFile1);
    };
    const onInput2 = (value) => {
      console.log("calling on input2", value);

      if (!value) {
        signatureDataURL.value = null;
        signatureFile2.value = null;
      } else if (value instanceof File) {
        signatureDataURL.value = null;
        signatureFile2.value = value;
      } else {
        signatureDataURL.value = value;
        signatureFile2.value = null;
      }
    };
    return {
      // state
      ...toRefs(state),
      signaturePad_inspected,
      signaturePad_inspector,
      signatureDataURL,
      signatureFile1,
      signatureFile2,
      // methods
      clearSignature1,
      clearSignature2,
      saveSignature,
      onInput1,
      onInput2,
    };
  },
});
